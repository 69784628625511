.AddOrganizationButtons {
    border-radius: 1.25rem;
    width : 25%;
    background: #3764D4!important;
    margin: 2px;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-right: 40px;
}

.Title {
    background: #FF0000 !important;
}

.btn-primary.system {
    font: var(--font-def-text-buttonlabel);
    border-color: var(--color-system-primary-dark);
    border-radius: 1.25rem;
    min-width:150px;
    background-color: var(--color-system-primary-dark);
    color:white;
}

.btn-secondary.system {
    font: var(--font-def-text-buttonlabel);
    border-color: var(--color-system-accent-light);
    border-radius: 1.25rem;
    min-width:150px;
    background-color: var(--color-system-primary-light);
    color:var(--color-system-primary-dark);
}

.btn-primary.people {
    font: var(--font-def-text-buttonlabel);
    border-color: var(--color-people-primary-dark);
    border-radius: 1.25rem;
    min-width:150px;
    background-color: var(--color-people-primary-dark);
    color:white;
}

.btn-secondary.people {
    font: var(--font-def-text-buttonlabel);
    border-color: var(--color-people-accent-light);
    border-radius: 1.25rem;
    min-width:150px;
    background-color: var(--color-people-primary-light);
    color:var(--color-people-primary-dark);
}

.btn-primary.rpm {
    font: var(--font-def-text-buttonlabel);
    border-color: var(--color-rpm-primary-dark);
    border-radius: 1.25rem;
    min-width:150px;
    background-color: var(--color-rpm-primary-dark);
    color:white;
}

.btn-secondary.rpm {
    font: var(--font-def-text-buttonlabel);
    border-color: var(--color-rpm-accent-light);
    border-radius: 1.25rem;
    min-width:150px;
    background-color: var(--color-rpm-primary-light);
    color:var(--color-rpm-primary-dark);
}

.btn-primary.error {
    font: var(--font-def-text-buttonlabel);
    border-color: var(--color-error-primary-dark);
    border-radius: 1.25rem;
    min-width:150px;
    background-color: var(--color-error-primary-dark);
    color:white;
}

.form-actions {
  margin-top: var(--space-md);
  margin-bottom: var(--space-xs);
}

.form-actions > button+button {
  margin-left: var(--space-sm);
}

.org-selection-list {
  border-bottom:1px solid var(--border-color-normal);
  border-top:1px solid var(--border-color-normal);
  padding-top:var(--space-xxs);
  padding-bottom:var(--space-xxs);
}

.modal-header {
    border-bottom: 0 none;
}

.modal-footer {
    border-top: 0 none;
}
