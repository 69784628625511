#ACTIVATElogo {
    width : 180px;
}

#logoutButton {
    border-radius: 50% !important;
    border: none;
    height : var(--icon-menu-size);
    width : var(--icon-menu-size);
    margin : 10px;
    color : var(--color-system-primary-dark);
    background-color: var(--color-system-primary-light);
}

#navContainer {
	  box-shadow: var(--panel-shadow);
    background-color: var(--bg-color-base);
}

.menu-expand-button {
    color : black;
    background-color: white;
    border:none;
}

.nav-help-links {
	justify-content: end;
}

.nav-help-links a.nav-link {
	color: var(--text-color-base);
	text-decoration:none;
	font: var(--font-def-h4);
	padding:0;
	margin-right:1.5em;
}

.nav-help-links a.nav-link:hover {
	text-decoration:underline;
}
.nav-user-info {
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction:column;
	border-left:1px solid var(--border-color-normal);
	padding-left:var(--space-xxs);

}
.nav-user-info p {
	margin-bottom:10px;
	height:1em;
	white-space:nowrap;
}

.nav-user-name {
	font:var(--font-def-h2);
}