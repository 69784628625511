td[role="columnheader"].dx-datagrid-action {
    background-color: white !important;
    font-family: "Inter-Semibold" !important;
    font-size: 14px;
    line-height: 18px;
    border-bottom: 0 !important;
}


td[role="columnheader"] {
    background-color: white !important;
    border-bottom: 0 !important;
}

.dx-datagrid-content {
    background-color: white !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-bottom: 0px !important;
}

.panel-header{
  display:flex;
  justify-content: space-between;
  align-items:baseline;
  margin-bottom:var(--space-xs);
}
.panel-header h2 {margin-bottom:0}

.page-panel section {margin-bottom:var(--space-xs);}
.page-panel section:last-child {margin-bottom:0}

.page-panel h2.panel-section,
.page-panel h3.panel-section {
  padding-bottom:0.5rem;
  border-bottom: 1px solid var(--border-color-normal);
}

.page-panel h2.accordion-header {margin-bottom:0}