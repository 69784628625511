

.points-of-contact h3 {
  margin-bottom:0;
  font:var(--font-def-text-label);
}

.points-of-contact .contacts {
  display:flex;
  flex-wrap:wrap;
  border-bottom:1px solid var(--border-color-normal);
}
.points-of-contact .contacts p {
  white-space:nowrap;
  flex-basis:50%;
  flex-grow:1;
  padding-right:1em;
}

.site-name {
  font:var(--font-def-text-section);
}