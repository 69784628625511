#adminContainer {
    margin-right: 0px;
    margin-left: 0px;
    transition: all 0.3s;
}

#adminSidebar {
    padding-left: 0px !important;
}

.caption {
    background-color: transparent;
    color: #0d6efd;
    cursor: pointer;
}