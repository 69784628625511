@font-face {
	font-family: "Inter-Regular";
	src: local("Inter-Regular"),
		url("../src/fonts/Inter/static/Inter-Regular.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "Inter-Bold";
	src: local("Inter-Bold"),
		url("../src/fonts/Inter/static/Inter-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Semibold";
	src: local("Inter-Semibold"),
		url("../src/fonts/Inter/static/Inter-SemiBold.ttf") format("truetype");
}

.font-face-activate-heading1 {
	font-family: "Inter-Regular";
	font-size: 24px;
	line-height: 30px;
}

.font-face-activate-heading2 {
	font-family: "Inter-Bold";
	font-size: 18px;
	line-height: 24px;
}

.font-face-activate-heading3 {
	font-family: "Inter-Semibold";
	font-size: 16px;
	line-height: 24px;
}

.font-face-activate-heading4 {
	font-family: "Inter-Semibold";
	font-size: 14px;
	line-height: 18px;
}

.font-face-activate-main-text {
	font-family: "Inter-Regular";
	font-size: 14px;
	line-height: 20px;
}

.font-face-activate-main-text-bold {
	font-family: "Inter-Bold";
	font-size: 14px;
	line-height: 20px;
}

.font-face-activate-secondary-text {
	font-family: "Inter-Regular";
	font-size: 12px;
	line-height: 16px;
}

.font-face-activate-caption {
	font-family: "Inter-Semibold";
	font-size: 12px;
	line-height: 16px;
}

.font-face-activate-label {
	font-family: "Inter-Bold";
	font-size: 12px;
	line-height: 16px;
}

input::-ms-reveal {
	display: none;
}

.breadcrumb-item+.breadcrumb-item::before {
	content: ">" !important;
	align-self: baseline;
	font-family: "Inter-Regular";
	font-size: 14px;
}

body {
	width: 100%;
	height: 100vh;
}

a {
	text-decoration: none;
	cursor: pointer;
}



/* override dx-theme-generic-typography */
/* the proper way to do this is to build a custom DX-theme */
body.dx-theme-generic-typography {
	color: var(--color-text-base);
	background-color: var(--bg-color-secondary);
}

.dx-theme-generic-typography h1 {
	font: var(--font-def-h1);
}

.dx-theme-generic-typography h2 {
	font: var(--font-def-h2);
}

.dx-theme-generic-typography h3 {
	font: var(--font-def-h3);
}

.dx-theme-generic-typography h4 {
	font: var(--font-def-h4);
}

.dx-theme-generic-typography {
	font: var(--font-def-text-main);
	color: var(--text-color-base);
}

.dx-theme-generic-typography input {
	font: var(--font-def-text-main);
	color: var(--text-color-base);
}

.dx-theme-generic-typography a {
	color: var(--link-color);
	text-decoration: none;
}

.dx-theme-generic-typography a:hover {
	text-decoration: underline;
}

.dx-theme-generic-typography small {
	font-weight: normal;
	font: var(--font-def-text-secondary);
}

.dx-datagrid-text-content p {
	margin-bottom: 0
}

.dx-datagrid-text-content p small {
	font-weight: normal;
}

.dx-datagrid-rowsview .dx-row.dx-group-row td {
	color: var(--link-color);
	font-weight: normal;
}

.dx-tabs {
	border: none;
	min-width: 500px;
	width: 500px;
}

.dx-tabs .dx-tab-selected {}

/* Error text shown in red */
.error-text {
	color: var(--text-color-error);
}

.feather-red-12 {
	width: 12px;
	height: 12px;
	stroke: red;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	fill: none;
}

p.validation-error {
	margin-top: 1rem;
	margin-bottom: 0;
	color: var(--color-error-primary-dark);
	background-color: var(--color-error-primary-lightest);
	border: 1px dotted var(--color-error-primary-dark);
	padding: 1rem;
	display: inline-block;
}

p.locked-account {
	color: var(--color-error-primary-dark);
	background-color: var(--color-error-primary-lightest);
	border: 1px dotted var(--color-error-primary-dark);
	padding: 1rem;
	display: inline-block;
	margin-bottom: 1rem;
	margin-top: 0;
}

/* establish everything underneath the banner */
.app-body-content {
	display: flex;
	height: 100vh;
}

.page-content {
	margin-top: var(--space-sm);
	margin-left: var(--space-sm);
	margin-right: var(--space-xs);
	width: 100%;
}

/* Set margin for column divs that wrap in response to smaller viewports */

.page-panel {
	background-color: var(--bg-color-base);
	padding: var(--space-xs) var(--space-xxs) var(--space-xs) var(--space-xxs);
	box-shadow: var(--panel-shadow);
	width: 100%;
	margin-bottom: var(--space-xxs);
}

.page-panel .form-group {
	margin-bottom: var(--space-xs);
	padding-right: var(--space-xs);
}

.panel-function-header {
	margin-bottom: 20px;
}

.page-panel h2 {
	margin-bottom: var(--space-xs);
}

.btn {
	padding: 0.5rem 0.75rem;
}

.btn.btn-link {
	color: var(--link-color);
	text-decoration: none;
}

.btn.btn-link:hover {
	text-decoration: underline;
}

.btn.btn-link:visited {
	text-decoration: underline;
}


.breadcrumb .breadcrumb-item {
	font: var(--font-def-h1);
	color: var(--text-color-base);
}


label {
	font: var(--font-def-text-label);
	color: var(--color-text-base);
}

label.form-label {
	margin-bottom: 10px;
}

.form-label.required::after {
	content: "*" !important;
	color: var(--text-color-required);
}

.modal-body .form-group {
	margin-bottom: var(--space-xs);
	padding-right: var(--space-xs);
}

/* Reset button on filter-area */
.reset-control {
	align-self: flex-end;
	margin-left: auto;
}

.form-group.clear {
	align-self: flex-end;
}

.form-group h3.sub-section {
	display: block;
	margin-top: var(--space-sm);
	margin-bottom: var(--space-xs);

	border-bottom: 1px solid var(--border-color-normal);
	padding-bottom: 0.5rem;
}

/* Utility classes */
.nowrap {
	white-space: nowrap
}


/* Vital Thresholds ========== */
.threshold-box {
	display: inline-block;
}

.threshold-box>a.reset {
	float: right;
	line-height: 1.8em
}

.threshold-group {
	display: table;
	margin-bottom: var(--space-md);
	padding-bottom: 0;
	border-bottom: 1px solid var(--border-color-normal);
}

.threshold-title {
	display: table-row
}

.threshold-title>div {
	display: table-cell;
	border-bottom: 1px solid var(--border-color-normal);
}

.threshold-setting {
	display: table-row;
}

.threshold-setting>div {
	display: table-cell;
	min-width: 10rem;
	padding: 1em 0;
}

.threshold-setting>div .form-control {
	display: inline;
	width: auto;
}

.threshold-setting>div.setting-label {
	min-width: 6rem
}

@media (min-width: 768px) {
	.threshold-setting>div.setting-label {
		min-width: 7rem
	}

	.threshold-setting>div {
		min-width: 10rem;
	}
}

@media (min-width: 992px) {
	.threshold-setting>div.setting-label {
		min-width: 9rem
	}

	.threshold-setting>div {
		min-width: 12rem;
	}
}

@media (min-width: 1200px) {
	.threshold-setting>div.setting-label {
		min-width: 12rem
	}

	.threshold-setting>div {
		min-width: 12rem;
	}
}

.text-na {
	font-size: 0.9em;
	color: var(--text-secondary);
}

.time-period-selector {
  align-self: flex-end;
  margin-left: auto;
}

.time-period-group label {
  white-space:nowrap;
}

.pdrm-metrics-tray {
  display: flex;
  justify-content:flex-start;
  gap:20px;
  margin-bottom:2rem;
}

.pdrm-metrics-tray .card {
  flex: 1 1 33%;
  max-width:30rem;
}
.pdrm-metrics-tray .card .card-body>.card-title {
  font-size:1.5rem;
}
.pdrm-metrics-tray .card .card-body>p {
  margin-bottom:0;
}

.pdrm-data-table {margin-top:2rem;} 
.triangle_down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--text-color-base);
}

.triangle_up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--color-todo-primary-dark);
}

.emptyTooltip {
  width: 5vw;
}

.noreadingsTooltip {
  width: 2vw;
}

.alignRight{
  margin-left: auto;
}