/* define size and margin for the login card */
.login-card {
  margin-top:var(--space-lg);
  width:480px;
  box-shadow: var(--panel-shadow);
}

/* Custom styles for <Card> elements */
.login-card .card-body {
  padding: var(--space-md) var(--space-xl);
}
.login-card .card-title {
  margin-bottom:var(--space-md);
}

/* Login card title is bold */
.login-card .card-title h1 {
  font: 24px/36px "Inter-Bold";
}
.login-card .card-title p {
  font: var(--font-def-text-main);
}

.login-card .form-group {
  margin-bottom: var(--space-sm);
}

.link-button {
  background: none!important;
  border: none;
  padding: 5px 0 5px 5px;
  font: var(--font-def-text-secondary);
  color: var(--link-color);
  float:right;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.link-button.btn.btn-primary:hover {
  color: var(--link-color);
  text-decoration:underline;
}

.submit-button {
  font: var(--font-def-text-buttonlabel);
  border-radius: 1.25rem;
  min-width : 175px;
  background-color: var(--color-people-primary-dark);
  clear:both;
  color: var(--color-primary-text);
}

.submit-button:disabled {
  background-color: var(--color-system-accent-light);
  border-color: var(--color-system-primary-dark);
  color: var(--text-color-disabled);
}

.btn-primary.submit-button:hover {
  background-color: #2A4BA1;
}

.one-time-password-entry {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.one-time-password-entry input {
  width: 40px;
  border: 1px solid var(--border-color-normal);
  font-size: 150%;
  height: 3rem;
  text-align: center;
}


.login-actions {
  text-align: center;
  margin-top: var(--space-lg);
  clear: both;
}

.login-footer {
  text-align: center;
  margin-top: var(--space-sm);
}

.login-footer .btn-link {
  text-decoration: none;
}