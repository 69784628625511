.view-toggle.btn-group {
  white-space:nowrap;
}
.view-toggle.btn-group .btn {
  padding:0.375rem 0.75rem;
}
.view-toggle.btn-group .btn.btn-outline-primary {
  background-color:#fff;
  border-color:var(--link-color);
  color:var(--link-color);
}
.view-toggle.btn-group .btn-check:checked+.btn.btn-outline-primary {
  color:white;
  background-color:var(--link-color);
  border-color:var(--link-color);
}

.plusButton {
  background-color: transparent;
  margin-left: 5px;
  color: #0d6efd;
  cursor: pointer;
}

.nolink {  
  margin-right: 5px;  
}
