.sidebar-icon {
    border-radius: 50% !important;
    border: none;
    height : 40px;
    width : 40px !important;
    margin : 10px;
    padding:0;
}

.sidebar-menu {
  box-shadow:var(--panel-shadow);
  clip-path: inset(0px -10px -10px -5px);
  height:100%;
  flex-direction:column;
  background-color: var(--bg-color-base);
  border-top:1px solid var(--border-color-lightest);
}

.sidebar-menu .list-group {
  border-radius:0;
  border:none;
}
.sidebar-menu .list-group-item {
  white-space:nowrap;
  border:none;
  padding:0;
}
.sidebar-menu .list-group-item .btn-link {
  font:var(--font-def-text-menulabel);
  color:var(--text-color-base);
  text-decoration:none;
  padding:0 1.5rem 0 0;
}

.sidebarList {
  padding : 0rem !important;
}

.peopleButton {
  color : var(--color-people-primary-dark);
  background-color: var(--color-people-primary-light);
}

.peopleButton:hover {
  color : #3764d4;
  background-color: #BFE0FE;
}

.peopleButton:focus {
  color : #3764d4;
  background-color: #BFE0FE;
}

.peopleButtonActive {
  color : white;
  background-color: var(--color-people-primary-dark);
}

.eventButton {
  color : #C56B10;
  background-color: #FDEDD2;
}

.eventButton:hover {
  color : #C56B10;
  background-color: #FAE3BB;
}

.eventButton:focus {
  color : #C56B10;
  background-color: #FAE3BB;
}

.eventButtonActive {
  color: white;
  background-color: #C56B10;
}

.messageButton {
  color : #644EB8;
  background-color: #E7E0FF;
}

.messageButton:hover {
  color : #644EB8;
  background-color: #D6CCFF;
}

.messageButton:focus {
  color : #644EB8;
  background-color: #D6CCFF;
}

.messageButtonActive {
  color: white;
  background-color: #644EB8;
}

.sidebar-icon.btn-primary.dataButton {
  color : var(--color-rpm-primary-dark);
  background-color: var(--color-rpm-primary-light);
}

.sidebar-icon.btn-primary.dataButton:hover {
  color : var(--color-rpm-primary-dark);
  background-color: var(--color-rpm-accent-light);
}

.sidebar-icon.btn-primary.dataButton:focus {
  color : var(--color-rpm-accent-light);
  background-color: var(--color-rpm-accent-dark);
}

.sidebar-icon.btn-primary.dataButton:active {
  color : white;
  background-color: var(--color-rpm-primary-dark);
}

.sidebar-icon.btn-primary.dataButton:focus {
  color : var(--color-rpm-accent-light);
  background-color: var(--color-rpm-accent-dark);
}

.sidebar-icon.btn-primary.dataButtonActive {
  color : white;
  background-color: var(--color-rpm-primary-dark);
}

.btn-primary.systemButton {
  color : var(--color-system-primary-dark);
  background-color: var(--color-system-primary-light);
  padding:0;
}

.btn-primary.systemButton:hover {
  color : var(--color-system-primary-dark);
  background-color: #C4C4C4;
}

.btn-primary.systemButton:focus {
  color : var(--color-system-primary-dark);
  background-color: #C4C4C4;
}

.btn-primary.systemButtonActive {
  color : var(--color-system-primary-light);
  background-color: var(--color-system-primary-dark);
}

button:focus {
  box-shadow: none !important;
}
