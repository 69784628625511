.dx-datagrid {  
    font:16px "Inter-Regular";  
    background-color: white;
  }
  td[role="columnheader"].dx-datagrid-action {
    background-color: #F6F6F6 !important;
  }
  .dx-datagrid .dx-column-lines > td {
    border-right: 0px;
    border-left: 0px;
  }