
.btn-function-button {
    color : var(--text-color-base);
    border-color: var(--color-system-primary-dark);
    background-color: var(--color-system-primary-light);
    font: var(--font-def-text-buttonlabel);
    border-radius: 1.25rem;
    min-width: 150px;
    padding-left:36px;
    padding-right:36px;
}

.btn.btn-function-button:hover {
    background-color: var(--color-system-accent-light);
    color : var(--text-color-base);
}

.btn.btn-action.action-icon {
    color:var(--link-color);
    background-color:white;
    border:none;
}
.btn.btn-action.action-icon:hover {
    background-color:var(--color-system-accent-light);
    border:none;
}
.addSiteButtons {
    color : #0d6efd !important;
    border-radius: 50%;
    background-color: #0d6efd;
    color: white !important;
    overflow:hidden;
    text-align:center;
    color:#e2eaf3;
    font-family:"Inter-Semibold";
    font-size:1.2em;
    font-weight:bold;
    text-decoration:none;
    margin-bottom: 5px;
}

.plusButton {
    background-color: transparent;
    margin-left: 20px;
    color: #0d6efd;
    cursor: pointer;
}

.nestLine {
    margin-left: 10px;
    color: #E0E0E0;
}

.dx-datagrid {
    font:var(--font-def-text-main);  
    background-color: white;
}

.dx-datagrid .dx-column-lines > td {
    border-right: 0px !important;
    border-left: 0px !important;
    border-top: 0px !important;
}

.dx-widget {
    font-family:"Inter-Regular";
}

.dx-datagrid-table-fixed {
    background-color: #F0F7FE !important;
    font-family:"Inter-Regular";
}

.dx-row {
    background-color: #F0F7FE !important;
}


.dx-datagrid-nowrap
{
    background-color: white !important;;
}

.dx-state-focused{
    background-color: white !important;
}

.dx-datagrid-action 
{
    background-color: transparent !important;
}

.dx-data-row{
    background-color: white !important;
}

.dx-datagrid-table
{
    background-color: white !important;
}

.dx-selection td{
    background: transparent !important;
}

.dx-field > .dx-lookup {
    flex: 1;
}

.single-row {
    width:auto;
    flex:  1
}

.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell{
    padding: 0px;
    padding-left: 20px;
    background-color: white !important;
}

#grid-container {
    min-width: auto;
 }
 
.dx-header-row{
    background-color: lightgray !important;
    color: black;
    font-family: "Inter-Semibold";
    font-size: 14px;
    line-height: 18px;
}

td[role="columnheader"].dx-datagrid-action {
    background-color: lightgray !important;
    font-family: "Inter-Semibold" !important;
    font-size: 14px;
    line-height: 18px;
}

td[role="columnheader"] {
    background-color: lightgray !important;
}
.dx-cell-focus-disabled{
    font-family:"Inter-Regular" !important;
    font-size: 14px;
    line-height: 18px;
}

.dx-command-expand .dx-datagrid-group-space .dx-cell-focus-disabled{
    font-family:"Inter-Regular" !important;
    font-size: 14px;
    line-height: 18px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-bottom: 0px;
}

.dx-command-edit {
    background-color: transparent !important;
}

.dx-command-expand {
    background-color: transparent !important;
}

.dx-cell-focus-disabled {
    background-color: transparent !important;
}