:root {
  /* override bootstrap variables */
  --bs-gutter-x:0rem;
    
  /* background colors */
  --bg-color-base:#ffffff;
  --bg-color-secondary:#f4f6f8;
  --bg-color-alternate:#c5c9Dc;

  /* text colors */
  --text-color-base: #111111;
  --text-color-secondary: #606060;
  --text-color-disabled: #9e9e9e;
  --text-color-error: #dd0000;
  --text-color-required: #f00000;
  
  /* link color */
  --link-color: #0074bd;
  
  /* border colors */
  --border-color-lightest: #f5f5f5;
  --border-color-light: #f0f0f0;
  --border-color-normal: #e6e9ee;
  --border-color-dark: #d5d5dd;
  --border-color-darkest: #aaaaaa;
  
  /* table */
  --table-header-bg-color: #f6f6f6;
  --table-header-border-color: #aaaaaa;
  --table-col-bg-color-highlighted: #f9f9f9;
  
  /* spacing */
  --space-xl: 80px;
  --space-lg: 60px;
  --space-md: 40px;
  --space-sm: 30px;
  --space-xs: 20px;
  --space-xxs: 15px;
  
  /* icon sizes */
  --icon-menu-size: 40px;
  
  /* category colors */
  --color-primary-text: #ffffff;
  
  /* error */
  --color-error-primary-lightest: #fef8fa;

  --color-error-primary-light: #fcf2f4;
  --color-error-accent-light: #fcdde4;
  --color-error-accent-dark: #f2c1cc;
  --color-error-primary-dark: #d32a2a;
  
  /* to-do and event */
  --color-todo-primary-light: #fffaf1;
  --color-todo-accent-light: #fdedd1;
  --color-todo-accent-dark: #fae3bb;
  --color-todo-primary-dark: #c46a10;
  
  /* rpm data and device */
  --color-rpm-primary-light: #ebf7f4;
  --color-rpm-accent-light: #daf2ec;
  --color-rpm-accent-dark: #bce5db;
  --color-rpm-primary-dark: #00816a;
  
  /* people */
  --color-people-primary-light: #f0f7fe;
  --color-people-accent-light: #d7ecfd;
  --color-people-accent-dark: #bedffd;
  --color-people-primary-dark: #3764d3;
  
  /* messages */
  --color-messages-primary-light: #f4f1ff;
  --color-messages-accent-light: #e6e0ff;
  --color-messages-accent-dark: #d6ccff;
  --color-messages-primary-dark: #644eb8;
  
  /* system */
  --color-system-primary-light: #f6f6f6;
  --color-system-accent-light: #e0e0e0;
  --color-system-accent-dark: #c4c4c4;
  --color-system-primary-dark: #4e4e4e;
  
  /* font definitions */
  --font-def-h1: 24px/125% "Inter-Regular";
  --font-def-h2: 18px/133% "Inter-Bold";
  --font-def-h3: 16px/150% "Inter-Semibold";
  --font-def-h4: 14px/18px "Inter-Semibold";
  
  --font-def-text-main:         14px/18px "Inter-Regular";
  --font-def-text-secondary:    12px/16px "Inter-Regular";
  --font-def-text-caption:      12px/16px "Inter-Semibold";
  --font-def-text-label:        14px/16px "Inter-Semibold";
  --font-def-text-menulabel:    14px/125% "Inter-Semibold";
  --font-def-text-buttonlabel:  16px/125% "Inter-Bold";
  --font-def-text-section:      16px/150% "Inter-Semibold";
  
  /* panel and card shadow */
  --panel-shadow: 2px 2px 6px rgba(0,0,0,0.1);
  
}

