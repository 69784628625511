#addRolesButton {
    color : 'black' !important;
    border-color: black !important;
    background-color: lightgray;
    border-radius: 1.25rem !important;
}

.actionButtonsDisabled {
    background-color: white !important;
    color : #0d6efd !important;
    border-color: white !important;
}