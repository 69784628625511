#adminContainer {
    margin-right: 0px;
    margin-left: 0px;
    transition: all 0.3s;
}

#adminSidebar {
    padding-left: 0px !important;
}

.dx-datagrid {
    font-family:"Inter-Regular";  
    background-color: white;
    font-size: 12px;
    line-height: 12px;
}

.dx-datagrid .dx-column-lines > td {
    border-right: 0px !important;
    border-left: 0px !important;
    border-top: 0px !important;
}

.dx-widget {
    font-family:"Inter-Regular";
}

.dx-datagrid-table-fixed {
    background-color: #F0F7FE !important;
    font-family:"Inter-Regular";
}

.dx-row {
    background-color: #F0F7FE !important;
}

.dx-datagrid-nowrap
{
    background-color: white !important;;
}

.dx-state-focused{
    background-color: white !important;
}

.dx-datagrid-action 
{
    background-color: transparent !important;
}

.dx-data-row{
    background-color: white !important;
}

.dx-datagrid-table
{
    background-color: white !important;
}

.dx-selection td{
    background: transparent !important;
}

.dx-field > .dx-lookup {
    flex: 1;
}

.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell{
    padding: 0px;
    padding-left: 10px;
    background-color: white !important;
}
 
.dx-header-row{
    background-color: lightgray !important;
    color: black;
    font-family: "Inter-Semibold";
    font-size: 14px;
    line-height: 18px;
}

td[role="columnheader"].dx-datagrid-action {
    background-color: #F0F0F0 !important;
    font-family: "Inter-Semibold" !important;
    font-size: 12px;
    line-height: 12px;
}

td[role="columnheader"] {
    background-color: lightgray !important;
}
.dx-cell-focus-disabled{
    font-family:"Inter-Regular" !important;
    font-size: 12px !important;
    line-height: 12px;
}

.dx-command-expand .dx-datagrid-group-space .dx-cell-focus-disabled{
    font-family:"Inter-Regular" !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-top: 2px;
    border-bottom: 0px;
}

.linkStyle {
    background-color: transparent;
    color: #0d6efd;
    cursor: pointer;
    font-weight: normal;
    font-family: "Inter-Regular";
    font-size: 12px !important;
}


#grid-container {
    min-width: auto;
}

.react-bs-container-body tr {
    border-top-style: hidden !important;
}
  
.react-bs-container-header tr {
    border-bottom-style: hidden !important;
}


.titles{
    color: black;
    font-weight: bold;
    font-family: "Inter-Regular";
    font-size: 14px !important;
}

.titlesTeamlet{
    font-weight: normal;
    font-family: "Inter-Regular";
    font-size: 14px !important;
    background-color: transparent;
    color: #0d6efd;
    color: red !important;
    cursor: pointer;
}

.titlesVitals{
    font-weight: normal !important;
    font-family: "Inter-Regular";
    font-size: 14px !important;
    color: black;
 }

.subTitles{
    color: black;
    font-weight: bold;
    font-family: "Inter-Regular";
    font-size: 11px !important;
}

.titlesDisabled{
    color: gray;
    font-weight: bold;
    font-family: "Inter-Regular";
    font-size: 12px !important;
}

.iconLeft {
    justify-content: left;
    margin: 0 auto;
}