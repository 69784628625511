.site-checkbox+.org-checkbox {
margin-top:1em;
}
.site-checkbox {
  margin-left:1em;
}

/*
.dx-datagrid {
  font:16px "Inter-Regular";  
  background-color: white;
}

.dx-datagrid .dx-column-lines > td {
  border-right: 0px !important;
  border-left: 0px !important;
  border-top: 0px !important;
}

.dx-widget {
  font-family:"Inter-Regular";
}

.dx-datagrid-table-fixed {
  background-color: #F0F7FE !important;
  font-family:"Inter-Regular";
}

.dx-row {
  background-color: #F0F7FE !important;
}


.dx-datagrid-nowrap
{
  background-color: white !important;;
}

.dx-state-focused{
  background-color: white !important;
}

.dx-datagrid-action 
{
  background-color: transparent !important;
}

.dx-data-row{
  background-color: white !important;
}

.dx-datagrid-table
{
  background-color: white !important;
}

.dx-selection td{
  background: transparent !important;
}

.dx-field > .dx-lookup {
  flex: 1;
}

.single-row {
  width:auto;
  flex:  1
}

.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell{
  padding: 0px;
  padding-left: 20px;
  background-color: white !important;
}

#grid-container {
  min-width: auto;
}

.dx-header-row{
  background-color: lightgray !important;
  color: black;
  font-family: "Inter-Semibold";
  font-size: 14px;
  line-height: 18px;
}

td[role="columnheader"].dx-datagrid-action {
  background-color: lightgray !important;
  font-family: "Inter-Semibold" !important;
  font-size: 14px;
  line-height: 18px;
}

td[role="columnheader"] {
  background-color: lightgray !important;
}
.dx-cell-focus-disabled{
  font-family:"Inter-Regular" !important;
  font-size: 14px;
  line-height: 18px;
}
.dx-page {
  outline: 2px solid  !important;
  outline-color: lightgray !important;
  background-color: white ;
  margin: 2px 2px 2px 2px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font: 16px "Inter-Regular"  !important;
  height: 30px;
  width: 30px;
}



.dx-field > .dx-lookup {
  flex: 1;
}
.single-row {
  width:auto;
  flex:  1
}

.dx-texteditor.dx-editor-outlined {
  font-family: "Inter-Regular" !important;
  min-height: "30px";
  width: "100%"
}*/