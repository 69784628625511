
.form-horizontal .control-label {
    text-align: left !important; /* !important added for priority in SO snippet. */
}


input[readonly], input[readonly="readonly"] {
    border : none;
    background-color: transparent !important;
}

.dx-list .dx-empty-message, .dx-list-item-content{
    padding: 5px!important;
    border: 0px !important;
    margin-left: 0px !important;
}

.page-panel.profile-information .form-label {margin-bottom:0}

.teamlet-name {
font:var(--font-def-text-main);color:red}

.ui-icon {
  border-radius:50%;
  border-width:0;
  display:inline-block;
  width:42px;
  height:42px;
  padding:8px;
  text-align:center;
  vertical-align:middle;
}
.ui-icon.people {
  color:var(--color-people-primary-dark);
  background-color:var(--color-people-primary-light);
}
.ui-icon.rpm {
  color:var(--color-rpm-primary-dark);
  background-color:var(--color-rpm-primary-light);
}
.ui-icon.todo {
  color:var(--color-todo-primary-dark);
  background-color:var(--color-todo-primary-light);
}

ul.team-list, ul.equipment-list {
  list-style-type:none;
  padding-left:0;
}

ul.team-list li, ul.equipment-list li {
  display:flex;
  margin:0 0 1rem 0;
  border-bottom:1px solid var(--border-color-normal);
  padding-bottom:var(--space-xxs);
  gap: 1rem;
}

ul.team-list li p, ul.equipment-list li p {display:inline-block;vertical-align:top;flex-basis:20em;}

ul.team-list      li p:nth-child(2), ul.equipment-list li p:nth-child(2) {flex-shrink:1;}
ul.team-list      li p:nth-child(2) {flex-basis:70%;}
ul.equipment-list li p:nth-child(2) {flex-basis:40%;}


ul.team-list li small, ul.equipment-list li small {font:var(--font-def-text-caption);}

ul.team-list li .actions, ul.equipment-list li .actions {flex-basis:5rem;white-space:nowrap;text-align: right;margin-left:auto}
ul.team-list li svg.action-icon, ul.equipment-list li svg.action-icon {color:var(--link-color); margin-left:10px}

ul.team-list li a, ul.equipment-list li a {color:var(--link-color);}


.modal-header {
  background-color:var(--color-system-primary-dark);
  color:white;
}

.modal-header.rpm {
  background-color:var(--color-rpm-primary-dark);
  color:white;
}
.modal-header.people {
  background-color:var(--color-people-primary-dark);
  color:white;
}

.modal-header.error {
  background-color:var(--color-error-primary-dark);
  color:white;
}
.modal-footer {padding-bottom:var(--space-xs);}

.modal-body h2 {margin-bottom:var(--space-sm);}

.modal-footer button+button {margin-left:var(--space-sm)}