.navButton {
    color : black;
    background-color: white;
    border-color: white;
}

.navButton:hover {
    color : black;
    background-color: white;
    border-color: white;
}

.navButton:focus {
    color : black !important;
    background-color: white !important;
    border-color: white;
}

.navButton:checked{
    color : black;
    background-color: white;
    border-color: white;
}