.card-body .list-group-item {
  padding-left:0;
  padding-right:0;
}

.card-body .card-title {
font:var(--font-def-h2);
}

.card-body .list-group .list-group-item .card-link {
font:var(--font-def-h3);
text-decoration:none;
}

.row{--bs-gutter-x:15px}